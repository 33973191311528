@media (min-width: 769px) and (max-width: 1024px) {
  .navlist,
  .login-btn {
    display: none;
  }
  .cover {
    padding: 1rem;
  }
  .ann-container {
    width: 85%;
  }
  .announcement {
    font-size: 1.5rem;
  }
  .cover-heading > h1,
  .cover-heading > h4 {
    font-size: 1.8rem !important;
  }
  .jsCover,
  .feCover {
    width: 40% !important;
  }
  .hireSteadfast > p {
    font-size: 1.8rem !important;
  }
  .contact-wrapper {
    flex-direction: column;
  }
  .form-container {
    width: 100%;
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .navlist,
  .login-btn {
    display: none;
  }

  .navbar-toggler {
    display: none !important;
  }
  .navbar-nav {
    height: 0 !important;
  }
  .cover {
    padding: 3rem 0.5rem;
  }
  .announcement {
    font-size: 1.5rem;
  }

  .cover-heading > h1,
  .cover-heading > h4 {
    font-size: 1.1rem !important;
  }
  .serv,
  .lastLine {
    font-size: 11px;
  }
  .ann-container {
    width: 90%;
  }
  .announcement {
    font-size: 14px;
  }

  .for-employers {
    flex-direction: column;
  }
  .for-jobseekers,
  .our-expertise {
    flex-direction: column-reverse;
  }
  .jsDetails,
  .feDetails,
  .exDetails {
    width: 100% !important;
    padding: 30px 0;
  }
  .our-expertise {
    padding: 20px !important;
  }
  .jsCover,
  .feCover,
  .exCover {
    width: 100% !important;
  }
  .exImg {
    margin: 0 !important;
  }

  .hireSteadfast p {
    font-size: 1.3rem !important;
  }
  .buttons {
    flex-direction: column;
  }
  .steadfastMain {
    font-size: 1.1rem;
  }
  .job-heading {
    flex-direction: column;
  }
  .footerWrapper {
    flex-direction: column;
  }

  .footerWrapper h3 {
    font-size: 20px;
  }
  .about {
    width: 100%;
    padding-top: 0;
  }
  .follow {
    width: 70%;
  }
  .aDetails {
    display: none;
  }
  .copyright {
    width: 100%;
    text-align: center;
  }
  .job-desc {
    font-size: 13px;
  }
  .steadfastMain {
    font-size: 14px;
  }
  .joinUs-details {
    flex-direction: column;
    align-items: center;
  }
  .joinUs-details > p {
    width: 90%;
    text-align: justify;
  }
  .contact-wrapper {
    padding: 30px;
    flex-direction: column;
  }
  .form-container {
    width: 100%;
  }
  .country-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .country-icon {
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 50px;
  }
  .officeAddress {
    width: 100% !important;
  }
  .officeAddress h5 {
    justify-content: center;
  }
  .phoneNo,
  .bdPhoneNo {
    width: 100%;
  }
  .eTitle h1 {
    font-size: 1.2rem;
  }
  .steadfast-cards {
    flex-direction: column;
  }
  .steadfast-card {
    width: 100%;
    padding-top: 10px;
  }
  .questions-wrapper {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .navlist,
  .login-btn {
    display: none;
  }

  .navbar-toggler {
    display: none !important;
  }
  .navbar-nav {
    height: 0 !important;
  }
  .cover {
    padding: 3rem 0.5rem;
  }
  .announcement {
    font-size: 1.5rem;
  }
  /* .cover {
    padding-left: 1rem !important;
  } */
  .cover-heading > h1,
  h4,
  .cover-heading span {
    margin-left: 10px;
  }
  .cover-heading > h1,
  .cover-heading > h4 {
    font-size: 1rem !important;
  }
  .serv,
  .lastLine {
    font-size: 11px;
  }
  .ann-container {
    width: 90%;
  }
  .announcement {
    font-size: 14px;
  }

  .for-employers {
    flex-direction: column;
  }
  .for-jobseekers,
  .our-expertise {
    flex-direction: column-reverse;
  }
  .jsDetails,
  .feDetails,
  .exDetails {
    width: 100% !important;
    padding: 30px 0;
  }
  .our-expertise {
    padding: 20px !important;
  }
  .jsCover,
  .feCover,
  .exCover {
    width: 100% !important;
  }
  .exImg {
    margin: 0 !important;
  }

  .hireSteadfast p {
    font-size: 1.1rem !important;
  }
  .buttons {
    flex-direction: column;
  }
  .steadfastMain {
    font-size: 1.1rem;
  }
  .job-heading {
    flex-direction: column;
  }
  .joinUs-heading > h1 span {
    font-size: 2.5rem;
  }
  .contact-wrapper {
    padding: 10px !important;
  }
  .form-wrapper {
    padding: 20px;
  }
  .google-map {
    padding: 10px;
  }
  .spDetails > p {
    width: 90%;
  }
  .footerWrapper {
    flex-direction: column;
  }
  .footerWrapper h3 {
    font-size: 20px;
  }
  .about {
    width: 100%;
    padding-top: 0;
  }
  .follow {
    width: 70%;
  }
  .aDetails {
    display: none;
  }
  .copyright {
    width: 100%;
    text-align: center;
  }
  .job-desc {
    font-size: 13px;
  }
  .steadfastMain {
    font-size: 14px;
  }
  .joinUs-details {
    flex-direction: column;
    align-items: center;
  }
  .joinUs-details > p {
    width: 90%;
    text-align: justify;
  }
  .contact-wrapper {
    padding: 30px;
    flex-direction: column;
  }
  .form-container {
    width: 100%;
  }
  .country-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .country-icon {
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 50px;
  }
  .officeAddress {
    width: 100% !important;
  }
  .officeAddress h5 {
    justify-content: center;
  }
  .phoneNo,
  .bdPhoneNo {
    width: 100%;
  }
  .eTitle h1 {
    font-size: 1.2rem;
  }
  .steadfast-cards {
    flex-direction: column;
  }
  .steadfast-card {
    width: 100%;
    padding-top: 10px;
  }
  .questions-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .navlist,
  .login-btn {
    display: none;
  }
  .cover {
    padding: 3rem 0;
  }
  .announcement {
    font-size: 1.5rem;
  }
  .logo {
    width: 40%;
  }
  .logoName > span,
  .logoName {
    font-size: 14px !important;
  }
  .logo-wrapper {
    margin: 0;
  }
  .navbar-toggler {
    display: none !important;
  }
  .navbar-nav {
    height: 0 !important;
  }
  .cover {
    padding-left: 1.2rem !important;
  }
  .cover-heading > h1,
  .cover-heading > h4 {
    font-size: 1.1rem !important;
  }
  .serv,
  .lastLine {
    font-size: 11px;
  }
  .ann-container {
    width: 90%;
  }
  .announcement {
    font-size: 14px;
  }

  .for-employers {
    flex-direction: column;
  }
  .for-jobseekers,
  .our-expertise {
    flex-direction: column-reverse;
  }
  .jsDetails,
  .feDetails,
  .exDetails {
    width: 100% !important;
    padding: 30px 0;
  }
  .our-expertise {
    padding: 20px !important;
  }
  .jsCover,
  .feCover,
  .exCover {
    width: 100% !important;
  }
  .exImg {
    margin: 0 !important;
  }

  .hireSteadfast p {
    font-size: 1rem !important;
  }
  .buttons {
    flex-direction: column;
  }
  .steadfastMain {
    font-size: 1.1rem;
  }
  .job-heading {
    flex-direction: column;
  }
  .joinUs-heading > h1 span {
    font-size: 2rem !important;
  }
  .contact-wrapper {
    padding: 10px !important;
  }
  .form-wrapper {
    padding: 20px;
  }
  .google-map {
    padding: 10px;
  }
  .spDetails > p {
    width: 90%;
  }
  .footerWrapper {
    flex-direction: column;
  }

  .footerWrapper h3 {
    font-size: 20px;
  }
  .about {
    width: 100%;
    padding-top: 0;
  }
  .follow {
    width: 70%;
  }
  .aDetails {
    display: none;
  }
  .copyright {
    width: 100%;
    text-align: center;
  }
  .job-desc {
    font-size: 13px;
  }
  .steadfastMain {
    font-size: 14px;
  }
  .joinUs-details {
    flex-direction: column;
    align-items: center;
  }
  .joinUs-details > p {
    width: 90%;
    text-align: justify;
  }
  .contact-wrapper {
    padding: 30px;
    flex-direction: column;
  }
  .form-container {
    width: 100%;
  }
  .country-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .country-icon {
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 50px;
  }
  .officeAddress {
    width: 100% !important;
  }
  .officeAddress h5 {
    justify-content: center;
  }
  .phoneNo,
  .bdPhoneNo {
    width: 100%;
  }
  .eTitle h1 {
    font-size: 1.2rem;
  }
  .steadfast-cards {
    flex-direction: column;
  }
  .steadfast-card {
    width: 100%;
    padding-top: 10px;
  }
  .questions-wrapper {
    display: flex;
    flex-direction: column;
  }
}
