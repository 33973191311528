.cover-container {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/steadfast-cover.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.cover {
  /* width: 100vw; */

  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5rem;
}

.cover-heading {
  color: white;
  border-left: 5px solid #f1b502;
  border-width: 5px;
}
.cover-heading > h1,
h4,
.cover-heading span {
  margin-left: 20px;
}
.cover-heading > h4 {
  font-size: 35px;
  font-weight: 600;
  margin-top: 10px;
}
.cover-heading > h1 {
  font-size: 35px;
  font-weight: 600;
}
.cover-heading .serv {
  display: block;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 10px;
}
.lastLine {
  display: block;
  color: #b3b5b4;
  padding-bottom: 10px;
  font-weight: 600;
}
