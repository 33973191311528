.joinUs-cointainer {
  padding: 50px 0;
}
.joinUs-heading {
  text-align: center;
  /* padding-top: 6%; */
}
.joinUs-heading > h1 {
  /* display: block; */
  font-size: 1.5rem;
  margin-bottom: 3rem;
  color: #f1b502;
  font-weight: bolder;
}
.joinUs-heading > h1 > span {
  font-size: 3rem;
  color: black;
  /* color: #01b178; */
  /* color: #f1b502; */
  /* FFDD00 */
}
.joinUs-heading > span {
  /* border: 1px solid black; */
  padding: 15px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #f1b502;
  color: white;
  font-weight: bolder;
}
.joinUs-details {
  background-color: black;
  color: white;
  width: 100%;
  margin-top: 6rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  padding: 100px 0;
  gap: 50px;
  align-items: center;
}
.joinUs-details > h1 {
  text-align: center;
  margin: 0;
  text-transform: capitalize;
  border-radius: 40px 100%;
  box-shadow: 50px 10px 80px 0px #f1b502;
  height: fit-content;
}
.joinUs-details > p {
  width: 50%;
  /* line-height: 3.5vh; */
  margin: 0;
  /* font-weight: 500; */
}
.vacancies {
  padding-top: 6rem;
}
.vacancies > h1 {
  margin: 0;
  margin-left: 2rem;
  font-size: 2rem;
  font-weight: bolder;
}

.vacancies > h1 > span {
  color: #f1b502;
}
