@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap");
.ann-container {
  /* position: absolute; */
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  /* margin-top: 70px; */
  /* margin-left: 300px; */
  width: 70%;
  /* width: 750px; */
}
.ann-wrapper {
  display: flex;
  flex-shrink: 0;
  /* height: 50px; */
  align-items: center;
  animation: slide-left 15s linear infinite;
  font-family: impact, sans-serif;
  background: none;
}

.announcement {
  /* color: red; */
  font-size: 2rem;
  font-weight: 600;
  padding-left: 0.5rem;
  color: red;
  /* color: #6d90af; */
  font-family: "Montserrat", sans-serif;
  font-style: italic;
}
@keyframes slide-left {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    /* -webkit-transform: translateX(-300%); */
    transform: translateX(-100%);
  }
}
