.footer-container {
  width: 100%;
  background-color: black;
  color: white;
  /* margin-top: 80px; */
}
.footerWrapper {
  margin: auto;
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}
.about {
  width: 50%;
}
.about > .aDetails article,
.openningHours > ul > li > span,
.address span {
  font-size: 14px;
  line-height: 20px;
}

.address {
  padding-top: 20px;
}
.address span {
  display: inline-block;
  padding-bottom: 10px;
  margin-left: 50px;
}
.flagIcon {
  position: absolute;
  /* padding-top: 10px; */
}
.openningHours > ul,
.follow > ul {
  padding: 0;
}
.openningHours > ul > li {
  list-style: none;
  padding: 3px;
}
.footerWrapper h3 {
  color: #f1b502;
}
.steadfastLogo-container {
  display: flex;
  align-items: center;
}
.steadfast-logo {
  width: 42px;
  height: 30px;
  padding-right: 10px;
}
.openningIcon {
  position: absolute;
  padding-top: 4px;
  color: #f1b502;
}
.openningHours > ul > li > span {
  padding-left: 20px;
}

.follow h3 {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: center;
}
.follow > ul > li {
  list-style: none;
  font-size: 25px;
  cursor: pointer;
}
.follow > ul > li > a {
  color: white;
  margin-right: 30px;
  transition: 0.5s ease;
}
.follow ul li a:hover {
  color: #f1b502;
  transform: scale(1.1);
}
.follow > ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright {
  width: 35%;
  margin: auto;
  margin-top: 30px;
  padding-bottom: 10px;
}
.copyright > span {
  font-size: 12px;
}
.steadfastMain {
  background-color: #b4f7d4;
  /* background-color: #f1b502; */
  color: #2d3133;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
.steadfastMain span {
  padding-right: 10px;
  font-weight: bold;
  /* color: black; */
}
.steadfastLink {
  text-decoration: none;
  font-weight: bold;
}
