:root {
  --green: #007752;
  --yellow: #ffdd00;
  --orange: #f1b502;
  --silver: #f2f2f2;
}

.eTitle {
  background-image: url("../../assets/employer.jpeg");
  width: 100%;
  min-height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eTitle > h1 {
  /* margin: 0; */
  color: white;
  /* width: 60%; */
  text-align: center;
  /* margin: auto; */
  /* padding-top: 4em; */
  font-size: 2rem;
}
.eTitle > h1 > span {
  color: var(--yellow);
}

.steadfast-cards {
  display: flex;
  position: relative;
  background-color: var(--silver);
  justify-content: center;
  padding: 25px;
  line-height: 1.5rem;
}
.steadfast-card {
  width: 40%;
  text-align: justify;
  margin: auto;
}
.steadfast-card > p {
  font-size: 14px;
}
.steadfast-card > h3 {
  font-size: 2rem;
}
.sep {
  border: 1px solid rgba(56, 56, 56, 0.315);
}
.sIcon {
  position: absolute;
  padding-left: 15px;
  /* padding-top: -15px; */
  font-size: 2.5rem;
}

.questions-container {
  width: 90%;
  margin: 100px auto;
}
.qTitle {
  text-align: center;
}
.qTitle > h1 {
  margin-top: 0;
}
.questions-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35vw, 1fr));
  gap: 20px;
  margin: 3rem 0;
  position: relative;
  /* padding-top: 5%; */
  /* margin-bottom: 20%; */
}
.qa {
  text-align: justify;
  border-left: 1px solid;
  border-color: var(--orange);
  border-width: 5px;
  /* height: 80%; */

  padding-left: 20px;
}
.qa > h2 {
  margin-top: 0;
  font-size: large;
}
.qa > p {
  font-size: 14px;
  line-height: 1.8rem;
}
.qaSep {
  border: 1px solid black;
}
.contactUs-container {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
  color: white;
  /* margin-top: 30%; */
}
.cImg-container {
  width: 100%;
}
.cImg {
  /* width: inherit; */
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.cDetails {
  position: absolute;
  top: 4rem;
  margin-left: 2vmax;
}
.cDetails > p {
  font-size: 1rem;
  padding-bottom: 10px;
}

.cDetails button {
  width: 200px;
  height: 50px;
  border: none;
  background-color: var(--yellow);
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}
.cDetails button:hover {
  background-color: var(--orange);
  color: white;
}
