:root {
  --green: #007752;
  --yellow: #ffdd00;
  --orange: #f1b502;
  --silver: #f2f2f2;
}
.contact-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
}

.heading-container {
  background-color: var(--silver);
  padding: 50px;
}
.heading-wrapper {
  width: 60%;
  margin: auto;
  text-align: justify;
}
.heading-wrapper p {
  line-height: 1.8vmax;
  border-left: 15px double var(--orange);
  padding-left: 10px;
  margin-top: 0;
}

.country-icon {
  display: flex;
  margin-bottom: 80px;
}
.country-icon span {
  color: #747474;
}
.phoneNo {
  display: flex;
  padding-top: 5px;
  font-weight: bolder;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}
.bdPhoneNo > span {
  font-weight: bolder;
  padding-top: 5px;
  margin-left: 15px;
}
.officeAddress h5 {
  display: flex;
  align-items: center;
  font-weight: 700;
}

.pIcon {
  font-size: 25px;
  color: var(--orange);
}
.country-icons {
  width: 90%;
}

.officeAddress {
  margin-left: 20px;
}
.form-container {
  width: 45%;
}
.form-wrapper {
  padding: 50px;
  border-radius: 10px;
  box-shadow: -2px 3px 35px 8px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: -2px 3px 35px 8px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: -2px 3px 35px 8px rgba(0, 0, 0, 0.46);
}
.form-container h1 {
  font-size: 2rem;
}
.contactForm input {
  display: block;
  width: 100%;
  margin-top: 20px;
  font-size: 1.2rem;
  border: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
}

.contactForm textarea {
  width: 100%;
  height: 25vh;
  padding-top: 2rem;
  font-size: 1.2rem;
  border: 1px solid black;
  border-top: none;
  border-left: none;
  border-right: none;
  resize: vertical;
  margin-bottom: 1.5rem;
}

.policy > label {
  display: block;
  font-size: 1.3rem;
  font-weight: 600;
  padding-bottom: 10px;
}
#policy {
  margin-left: 0;
  margin-right: 10px;
}
.policy > span {
  color: #65656b;
  display: block;
}

.submit {
  width: 200px;
  height: 45px;
  margin-top: 2rem;
  border: none;
  background-color: var(--yellow);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.submit:hover {
  background-color: var(--orange);
  color: white;
}
.google-map {
  padding: 40px;
}
.google-map iframe {
  width: 100%;
}
