.find-container {
  background-image: url("../../assets/find.png");
  width: 100%;
  min-height: 200px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* object-fit: contain; */
  /* background-color: #f1b502; */
  /* background-color: #ffdd00; */
  padding: 50px 0;
}
.findWrapper {
  width: 90%;
  margin: auto;
}
.findWrapper > h1 {
  margin-top: 0;
  /* padding-top: 50px; */
}
.findWrapper > h3 {
  font-size: 18px;
  font-weight: 400;
  white-space: inherit;
}
.findWrapper > h3 > span {
  /* color: #01a06d; */
  font-weight: 600;
}
.filter-container {
  background-color: #ffdd00;

  height: 60px;
  display: flex;
}
.filterWrapper {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}
.role-types {
  display: flex;
  width: 25%;
  justify-content: space-between;
}
.location-container {
  margin-right: 10%;
}
.location {
  padding-right: 40%;
  background: transparent;
  border: none;
  font-size: medium;
}

.role-type,
.location {
  cursor: pointer;
  font-weight: 600;
}
.jobCards-container {
  /* width: 95%; */
  /* margin: auto; */
  padding-top: 80px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  /* display: grid; */
  /* gap: 15px; */
  /* grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); */
}
.moreBtn {
  width: 150px;
  /* height: 70px; */
  margin: 50px auto;
}
.moreBtn > button {
  cursor: pointer;
  width: 150px;
  height: 50px;
  border: none;
  margin: auto;
  border-radius: 5px;
  background-color: #f1b502;
  font-size: medium;
  font-weight: 500;
}
.moreBtn > button:hover {
  background-color: #ffdd00;
}
.moreArrow {
  position: absolute;
  margin: 2px 8px;
}
.specialism {
  margin-top: 50px;
  background-image: url("../../assets/specialism.jpg");
  width: 100%;
  min-height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spDetails {
  background-color: rgba(0, 0, 0, 0.603);
  color: white;
  text-align: center;
  padding: 1rem 0;
}
.spDetails > h1 {
  color: #f1b502;
}
.spDetails > p {
  width: 60%;
  margin: auto;
  font-weight: 500;
  font-size: medium;
  padding-bottom: 10px;
}
