.search {
  display: flex;
  padding-top: 30px;
  /* align-content: center; */
  /* justify-content: center; */
}
.searchIcon {
  height: 36px;
  font-size: 55px;
  border-right: 5px solid black;
  padding-right: 16px;
}
.searchBox {
  width: 100%;
}
.searchBox > input {
  width: 100%;
  height: 35px;
  border: none;
  font-size: larger;
  font-weight: 500;
  padding-left: 32px;
  background: transparent;
}
textarea:focus,
input:focus {
  outline: none;
}
