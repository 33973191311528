.navWrapper {
  width: 100vw;
  height: fit-content;
  z-index: 100;
  max-width: 100%;
  background-color: black;
  box-shadow: 0 2px 20px 0 rgb(71, 71, 71);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  padding: 0;
}
.navbarContainer {
  padding: 0;
  position: relative;
}

.navContainer {
  position: relative;
  z-index: 999;
}
.navContainer a {
  text-decoration: none;
  color: white;
}
.logo {
  width: 100%;
  height: 80px;
}
.logo > img {
  max-width: 100%;
  max-height: 100%;
}
.logoName span {
  font-size: 25px;
  color: #f1b502;
}
.navlist {
  /* margin-left: 20rem; */
  width: 40%;
  margin: auto;
  /* display: flex; */
  /* justify-content: center; */
}
.navlist > a {
  margin: auto;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s linear;
}
.navlist > a:hover,
.navlist > a:focus {
  border-bottom: 3px solid #f1b502;
  transform: scale(1.1);
}

.logo-wrapper {
  display: flex;
  align-items: center;
}
.logo-wrapper a {
  text-decoration: none;
}
.logoName {
  font-size: 15px;
  list-style: none;
  color: white;
  display: inline;
  margin: 0;
  font-weight: bold;
}
.login-btn {
  width: 100px;
  border: none;
  padding: 5px 20px;
  background-color: #f1b502;
  color: white;

  cursor: pointer;
  margin-right: 10px;
}
.login-btn:hover {
  background-color: #e9bd39;
}
.menu {
  /* background-color: #f1b502; */
  color: white;
  display: block;
  margin-right: 20px;
  z-index: 999;
}
.menu-options {
  position: absolute;
  width: 100%;
}
