body {
  margin: 0;
  font-family: "Urbanist", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
}

main > div.findJob {
  width: 90vw;
  margin: auto;
}
.featuredContainer {
  /* display: flex; */
  width: 90vw;
  margin: auto;
}
h1.ftitle {
  /* width: 30%; */
  margin: 50px 0;
  padding-right: 80px;
  font-weight: 600;
}
.longArrow {
  font-size: 40px;
  position: absolute;
  color: #f1b502;
  padding-top: 1px;
  margin-left: 20px;
}
.hireSteadfast {
  background-color: #2d3133;
  color: white;
  /* padding: 4rem 0; */
}
.hireSteadfast p {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  padding-top: 50px;
}

.buttons {
  display: flex;
  width: 40%;
  margin: auto;
  gap: 30px;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  /* justify-content: space-between; */
}
.buttons button {
  /* margin-left: 50px; */
  width: 15rem;
  height: 50px;
  background: transparent;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  outline: none;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
}
.buttons button:hover {
  background-color: white;
  color: #2d3133;
  transition: all 0.5s ease;
}
