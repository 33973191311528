.service-container {
  background-color: black;
}
.serviceWrapper {
  width: 90%;
  margin: auto;
  padding: 50px 0;
  color: white;
  margin-top: 100px;
  position: relative;
}
.for-jobseekers,
.for-employers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.for-employers {
  margin-top: 20px;
}
.feCover,
.jsCover {
  width: 30%;
  height: fit-content;
  /* border-radius: 25px; */
  overflow: hidden;
}
.jsDetails,
.feDetails {
  width: 50%;
  text-align: justify;
  font-size: 16px;
  position: relative;
}
.jsDetails > h1,
.feDetails > h1 {
  font-size: 2rem;
}
.jsDetails > h4,
.feDetails > h4 {
  color: #f1b502;
  margin: 0;
  font-size: 1.2rem;
  padding-bottom: 10px;
}
.jsDetails button,
.feDetails button {
  cursor: pointer;
  width: 250px;
  height: 50px;
  border: none;
  background-color: #ffdd00;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
}
.jsDetails button:hover,
.feDetails button:hover {
  background-color: #f1b502;
}

.jsImg,
.feImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.our-expertise {
  padding-top: 100px;
  padding-bottom: 80px;
  margin: 0 auto;
  background-color: #f2f2f2;
}

.our-expertise {
  display: flex;
  justify-content: space-around;
}
.exDetails {
  position: relative;
  width: 40%;
  text-align: justify;
  line-height: 25px;
}
.exDetails > h4 {
  color: #f1b502;
  margin: 0;
}
.exDetails > ul {
  padding: 0;
}
.exDetails > ul > li {
  list-style: none;
}
.exCover {
  width: 45vw;
  height: 40vh;
}
.exImg {
  width: 100%;
  object-fit: cover;
  /* width: 60vh; */
  margin-top: 80px;
  box-shadow: 10px 15px 20px 5px rgb(2, 2, 2);
  /* height: 550px; */
}
.exIcon {
  position: absolute;
  padding-top: 4px;
  color: #f1b502;
}
.exDetails > ul > li > span {
  padding-left: 30px;
  font-weight: 500;
}
