.job-container {
  padding: 40px;
}
.job-container h1 {
  padding-top: 30px;
  font-weight: bolder;
}
.job-heading {
  display: flex;
  /* width: 70%; */
  gap: 30px;
  /* justify-content: space-between; */
  padding: 15px 0;
}
.job-heading span {
  font-weight: bold;
}
.jobIcon {
  margin-right: 10px;
  font-size: 25px;
  color: #f1b502;
}
.job-desc {
  text-align: justify;
  white-space: break-spaces;
  padding-bottom: 5%;
  /* width: 80%; */
}
.job-desc section {
  padding: 10px 0;
  letter-spacing: 1px;
}
.job-desc section > span {
  font-weight: bold;
  font-size: 20px;
}
.resume {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 50px;
}
.resume label {
  font-size: 1.1rem;
  font-weight: 700;
}
input[type="file"] {
  /* padding: 0 30px; */
  /* font-size: 1.3vmax; */
}
input::file-selector-button {
  border: none;
  background-color: rgb(37, 37, 37);
  color: white;
  padding: 5px 10px;
}
input::file-selector-button:hover {
  background-color: black;
}
.applyBtn {
  height: 70px;
  margin: auto;
}
.applyBtn button {
  cursor: pointer;
  width: 150px;
  height: 45px;
  border: none;
  background-color: #ffdd00;
  font-size: medium;
  font-weight: 500;
}
.applyBtn button:hover {
  background-color: #f1b502;
  color: white;
}
