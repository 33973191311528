.cardContainer {
  height: 450px;
  width: 310px;
  /* background-color: black; */
  /* color: white; */
  border: 0.5px solid #cfcfcf;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* border: none; */
  /* border-top: black 5px solid; */
}
.cardContainer:hover {
  box-shadow: 5px 2px 20px 5px rgb(41, 41, 41);
}
.card {
  box-sizing: content-box;
  width: 80%;
  height: 380px;
  /* background-color: black; */
  /* height: max-content; */
  --bs-card-border-color: none;
  margin: auto;
}
.cardItem {
  margin-top: 20px;
  /* height: 70px; */
}

.cardDetails {
  font-size: 13px;
  display: inline-block;
  padding-top: 5px;
}
.cardDetails.location {
  font-weight: 500;
  font-size: small;
}
.title {
  padding-left: 2px;
  font-size: 14px;
  font-weight: bold;
}
.skills,
.location {
  display: inline-block;
  width: 100%;
  height: 45px;
  /* white-space: normal;
  white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardTitle {
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: large;
  margin: 20px auto 0 auto;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(77, 76, 76, 0.295);
}

.detailsBtn {
  height: 70px;
  margin: auto;
}
.detailsBtn a {
  text-decoration: none;
}
.detailsBtn button {
  cursor: pointer;
  width: 150px;
  height: 45px;
  border: none;
  background-color: #ffdd00;
  font-size: medium;
  font-weight: 500;
}
.detailsBtn button:hover {
  background-color: #f1b502;
  color: white;
}
.detailsArrow {
  position: absolute;
  margin: 2px 8px;
}
.cardIcon {
  position: absolute;
  padding-top: 3px;
}
.title,
.cardDetails {
  padding-left: 25px;
}
